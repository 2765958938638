import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicineHaccpAnalysisPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Analisi Haccp'

  const description =
    'L’<b>HACCP</b> (<b><em>Hazard Analysis and Critical Control Points</em></b>) è un sistema di autocontrollo che ogni operatore nel settore della produzione e somministrazione di alimenti deve mettere in atto al fine di valutare e stimare pericoli e rischi e stabilire misure di controllo per prevenire l’insorgere di problemi igienici e sanitari.<br> Non avere un <b>piano di autocontrollo HACCP</b> secondo il Regolamento CE n. 852/2004 è reato punito con una sanzione di ca <b>2000,00 €</b>.<br> ING GROUP offre prelievi di campioni ed analisi di laboratorio per:<br><br> • <b>Igiene del processo:</b> analisi atte a verificare che il processo di lavorazione sia effettuato in opportuno stato igienico sanitario;<br> • <b>Potabilità delle acque:</b> analisi per verificare la conformità dell’acqua e di sistemi di immagazzinamento;'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
